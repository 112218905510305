import {Fab, makeStyles} from "@material-ui/core";
import { DataGrid, GridRowSelectedParams } from "@material-ui/data-grid";
import React, {useCallback, useEffect, useState} from "react";
import { useHistory } from "react-router";
import { Tenant } from "../../interfaces";
import Fetcher, { URLBuild } from "../../services/fetcher";
import {Add, Edit, Refresh} from "@material-ui/icons";
import {blue, green, red} from "@material-ui/core/colors";
import TenantDialog from "../../components/tenant-dialog";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: 1,
        height: '100%'
    },
    add: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2),
        backgroundColor: green['500']
    },
    edit: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(20),
        backgroundColor: red['500']
    },
    refresh: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(11),
        backgroundColor: blue['500']
    }
}));

const Home = () => {
    const classes = useStyles();
    const history = useHistory();
    const [tenants, setTenants] = useState<Tenant[]>([]);
    const [editing, setEditing] = useState(false);
    const [modal, setModal] = useState<{open: boolean, data: Tenant | undefined}>({open: false, data: undefined});

    const getData = useCallback(() => {
        Fetcher.get(URLBuild('/nahal/tenants',
            {
                pageSize: -1
            }))
            .then((res) => setTenants(res.body.tenants));
    }, []);
    useEffect(getData, [getData]);

    const onTenantSelected = (e: GridRowSelectedParams) => {
        if (editing) {
            setModal({open: true, data: e.data as Tenant})
        } else {
            history.push(`/tenants/${e.data.tenantId}/collaborators`)
        }
    }

    return (
        <div className={classes.root}>
            <DataGrid
                getRowId={(row) => row.tenantId}
                columns={[
                    { field: 'tenantId', headerName: 'Id', width: 300, sortable: false },
                    { field: 'name', headerName: 'Name', width: 300, sortable: false },
                    { field: 'host', headerName: 'Host', width: 300, sortable: false },
                    { field: 'sddFeeMilli', headerName: 'Sdd Fee Milli', width: 200, sortable: false },
                    { field: 'sddFixedFeeCts', headerName: 'Sdd Fixed Fee Cts', width: 200, sortable: false },
                    { field: 'sddFeeRejectedCts', headerName: 'Sdd Fee Rejected Cts', width: 200, sortable: false },
                    { field: 'bcFeeMilli', headerName: 'Bc Fee Milli', width: 200, sortable: false },
                    { field: 'bcFixedFeeCts', headerName: 'Bc Fixed Fee Cts', width: 200, sortable: false },
                ]}
                rows={tenants}
                rowCount={tenants.length}
                onRowSelected={onTenantSelected}
            />
            <TenantDialog tenant={modal.data} open={modal.open} onClose={() => {setModal({open: false, data: undefined});getData()}} />
            <Fab className={classes.add} onClick={() => setModal({open: true, data: undefined})}><Add /></Fab>
            <Fab className={classes.edit} style={editing ? {backgroundColor: 'grey'} : {}} onClick={() => setEditing(prev => !prev)}><Edit /></Fab>
            <Fab className={classes.refresh} onClick={getData}><Refresh /></Fab>
        </div>
    );
}

export default Home;